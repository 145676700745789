import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

const LandingAnimateSplash: React.FC = () => {
  // main logo animation times
  const [loopTimes, setLoopTimes] = useState(1);
  const [logoDone, setLogoDone] = useState(false);
  const [chargerDone, setChargerDone] = useState(false);
  const [comingSoonDone, setComingSoonDone] = useState(false);
  const [evLogoDone, setEvLogoDone] = useState(false);

  const [hideAnimation, setHideAnimation] = useState(false);

  // mainLogo flash animation
  const mainLogoFlashSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 300 },
    reset: true,
    onRest: () => {
      if (loopTimes < 2) {
        setLoopTimes(loopTimes + 1);
      } else {
        setLogoDone(true);
      }
    },
    key: loopTimes,
  });

  // mainLogo fadeout animation
  const mainLogoDisapperSpring = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { duration: 1300 },
    delay: 1000,
    onRest: () => setLogoDone(true),
  });

  // charger in animation
  const chargerInSpring = useSpring({
    from: { transform: "translate3d(0, -10%, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    config: { duration: 800 },
    delay: 2500,
    onRest: () => setChargerDone(true),
  });

  // coming soon animation
  const comingSoonSpring = useSpring({
    from: { opacity: 0 },
    to: chargerDone ? { opacity: 1 } : null,
    config: { duration: 800 },
    // delay: 4600,
    onRest: () => setComingSoonDone(true),
  });

  // evLogo animation
  const evLogoSpring = useSpring({
    from: { opacity: 0 },
    to: comingSoonDone ? { opacity: 1 } : null,
    config: { duration: 800 },
    // delay: 4600,
    onRest: () => setEvLogoDone(true),
  });

  // charger out animation
  const chargerOutSpring = useSpring({
    from: { opacity: 1 },
    to: evLogoDone ? { opacity: 0.3 } : null,
    config: { duration: 1000, easing: (t) => t },
  });

  const leftSpring = useSpring({
    from: { transform: "translate(0%, 0%)", opacity: 1 },
    to: { transform: "translate(-30%, 30%)", opacity: 0.6 },
    config: { duration: 2000 },
    delay: 2500,
  });

  const rightSpring = useSpring({
    from: { transform: "translate(0%, 0%)", opacity: 1 },
    to: { transform: "translate(30%, -30%)", opacity: 0.6 },
    config: { duration: 2000 },
    delay: 2500,
    onRest: () => setHideAnimation(true),
  });

  return (
    <div className="relative flex flex-col w-dvw h-dvh overflow-hidden bg-menuBase">
      {/* left top logo */}

      <div className="flex justify-between items-center m-6 laptop:m-10">
        <animated.img
          style={evLogoSpring}
          className="w-[8rem] h-[3rem]"
          src={require("../../../assets/images/asgard-ev-logo.png")}
          alt="asgard-ev-logo"
        />

        {/* right top logo */}
        <animated.img
          style={evLogoSpring}
          className="w-[120px] h-[32px]"
          src={require("../../../assets/images/asgard-ev-logo2.png")}
          alt="asgard-ev-logo2"
        />
      </div>

      {/* main logo */}
      <animated.img
        style={logoDone ? mainLogoDisapperSpring : mainLogoFlashSpring}
        className="w-[12.5rem] laptop:w-[31.25rem] h-auto laptop:h-[31.25rem] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50"
        src={require("../../../assets/images/supercharge-logo.png")}
        alt="charge-logo"
      />

      {/* charger image */}
      <div className="absolute w-full laptop:w-auto flex justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <animated.img
          className="laptop:w-[70%]"
          style={comingSoonDone ? chargerOutSpring : chargerInSpring}
          src={require("../../../assets/images/supercharge-charger.png")}
          alt="charger"
        />
      </div>

      {/* coming soon text - desktop */}
      <animated.img
        style={comingSoonSpring}
        className="hidden laptop:flex w-[60%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        src={require("../../../assets/images/coming-soon.png")}
        alt="coming-soon-desktop"
      />

      {/* coming soon text - mobile */}
      <animated.img
        style={comingSoonSpring}
        className="flex laptop:hidden w-[60%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        src={require("../../../assets/images/coming-soon-mobile.png")}
        alt="coming-soon-mobile"
      />

      <div className="absolute bottom-10 left-1/2 text-[0.5rem] text-gray-500 transform -translate-x-1/2 -translate-y-1/2 ">
        Copyright© 2024 ASGARD All rights reserved.
      </div>

      {/* opening cover */}
      {!hideAnimation && (
        <div className="fixed top-0 left-0 w-full h-full z-40">
          <div className="relative">
            {/* right triangle */}
            <animated.div
              style={rightSpring}
              className="absolute w-0 h-0 border-l-[100vw] border-t-[100vh] border-l-transparent border-t-[#222222]"
            ></animated.div>

            {/* left triangle */}
            <animated.div
              style={leftSpring}
              className="absolute w-0 h-0 border-r-[100vw] border-b-[100vh] border-r-transparent border-b-[#222222]"
            ></animated.div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingAnimateSplash;
